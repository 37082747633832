@import 'variables.scss';

.coordinator {
	.mdi:not(.button) {
		font-size: 26px;
		line-height: 1;
		display: inline-block;
		vertical-align: middle;
		margin-top: -4px;
		height: 24px;
	}

	.leftPane {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 56%;
		background-color: #f1f1f1;

		.contentMargins {
			margin: 10px 0 10px 10px;
		}

		.leftPaneWrapper {
			position: relative;
		}

		.topPane {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 65%;
			padding-left: var(--menu-width);
			transition: padding-left .3s;
			display: flex;
			flex-direction: column;
		}

		.bottomPane {
			position: absolute;
			top: calc(65% + 4px);
			bottom: 0;
			left: 0;
			right: 0;
			padding-left: var(--menu-width);
			display: flex;
			transition: padding-left .3s;
		}

		.horizontalBorder {
			position: absolute;
			top: 65%;
			right: 0;
			left: var(--menu-width);
			overflow: hidden;
			height: 8px;
			overflow: auto;
			border-bottom: 8px solid #e5e5e5;
			cursor: s-resize;
			z-index: 1;
			transition: left .3s;
		}

		.airports {
			padding: 8px;
			max-height: 300px;
			overflow: auto;
			flex: 0 1 auto;
		}

		.airlines {
			overflow: auto;
			flex: 1 1 auto;
		}

		.airport {
			width: 170px;
			height: 48px;
			margin: 5px;
			padding: 4px;
			border-radius: 8px;
			float: left;
			background-color: #ffffff;
			cursor: pointer;

			&.selected {
				background-color: #eef3f7;
				box-shadow: 0px 0px 10px 1px rgba(0,0,64,0.25);

				.airportCodeWrapper {
					background-color: #2a6496;
					color: #ffffff;
				}
			}

			.airportCodeWrapper {
				border-radius: 8px;
				float: left;
				width: 50%;
				height: 40px;
				padding: 4px;
				background-color: #e0e0e0;

				.airportCode {
					font-weight: bold;
					font-size: 28px;
					line-height: 32px;
				}
			}

			.weather {
				float: right;
				width: 50%;
				height: 40px;
				padding: 4px;
				overflow: hidden;
				line-height: 32px;
				color: #000000;

				.weatherIcon {
					display: inline-block;
					margin: -5px -4px -13px -4px;
					width: 40px;
					height: 40px;
					background-size: cover;

					&.withPrecip {
						margin: -8px -4px -10px -4px;
					}
				}
			}
		}

		.airline {
			width: 200px;
			height: 60px;
			margin: 10px;
			padding: 4px;
			box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.25);
			border-radius: 8px;
			float: left;
			background-color: #ffffff;
			cursor: pointer;
			border: 6px solid #ffffff;

			&.selected {
				background-color: #eef3f7;
				box-shadow: 0px 0px 10px 1px rgba(0,0,64,0.25);
				border-color: #2a6496;

				.airportCodeWrapper {
					color: #ffffff;
				}
			}

			.airlineLogo {
				width: 180px;
				height: 40px;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center center;
			}

			.airlineCode {
				color: #2a6496;
				font-weight: bold;
				line-height: 38px;
				font-size: 28px;
				text-align: center;
			}
		}

		.weather {
			float: right;
			width: 50%;
			height: 40px;
			padding: 4px;
			overflow: hidden;
			line-height: 32px;

			.weatherIcon {
				display: inline-block;
				margin: -5px -4px -13px -4px;
				width: 40px;
				height: 40px;
				background-size: cover;

				&.withPrecip {
					margin: -8px -4px -10px -4px;
				}
			}
		}
	}

	.verticalBorder {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 56%;
		overflow: hidden;
		width: 8px;
		overflow: auto;
		border-left: 8px solid #e5e5e5;
		cursor: w-resize;
		z-index: 3;
	}

	.hotConditions {
		.code {
			display: block;
			width: 24px;
			position: relative;
			margin: -23px 0 0 1px;
			text-align: center;
			font-size: 14px;
			color: #ffffff;
			font-weight: bold;
		}
	}

	.rightPane {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		overflow: hidden;
		left: calc(56% + 4px);
		overflow: auto;
		z-index: 2;

		table {
			margin-bottom: 25px;
		}

		.airportCodeWrapper {
			padding: 15px 20px 15px 15px;
			height: 70px;
			background-color: #eef3f7;
			overflow: hidden;

			.plus {
				color: #aaaaff;
				position: absolute;
				margin-left: -14px;
				font-size: 22px;
				margin-top: 20px;
				font-weight: bold;
			}

			.airportCode {
				font-weight: bold;
				font-size: 28px;
				background-color: #2a6496;
				color: #ffffff;
				border-radius: 6px;
				padding: 6px 8px;
				margin: 0 2px 0 8px;
			}

			.weatherIcon {
				display: inline-block;
				margin: -5px 0 -8px 5px;
				width: 40px;
				height: 40px;
				background-size: cover;

				&.withPrecip {
					margin: -5px 0 -10px 5px;
				}
			}

			.requestTime {
				display: inline-block;
				float: right;
				padding-top: 8px;
			}

			.refresh {
				float: right;
				padding: 5px;
				margin-left: 10px;
			}
		}

		.editWeatherType, .editTemperature, .showWeatherTypePopup, .showIntensityPopup {
			float: right;
			padding: 5px;
		}

		.weatherTypeSelection, .intensitySelection {
			margin-bottom: 10px;

			.caption {
				width: 175px;
				display: inline-block;
				line-height: 36px;
				vertical-align: middle;

				.mdi {
					margin-left: 3px;
					margin-right: 5px;
				}
			}

			.weatherType, .intensity {
				display: inline-block;
				line-height: 36px;
				vertical-align: middle;
			}
		}

		.text-center {
			text-align: center;
		}

		.row {
			display: flex;
			padding: 0;
			margin: 8px 0;
			width: 100%;

			.col-md-4 {
				width: 33.33333333%;
			}

			.col-md-8 {
				width: 66.66666667%;
			}
		}

		.col {
			-webkit-box-flex: 1;
			-webkit-flex: 1;
			-moz-box-flex: 1;
			-moz-flex: 1;
			-ms-flex: 1;
			flex: 1;
			display: block;
			padding: 5px;
			width: 100%;
		}

		.fluid {
			border: 1px solid gray;
			padding: 8px;

			.lineSample {
				display: inline-block;
				vertical-align: top;
				width: 60px;
				height: 15px;
				background-position: bottom;
				background-repeat: repeat-x;
				background-size: 16px 4px;

				&.type1 {
					background-image: linear-gradient(to right, #ffcc66 100%, #ffffff 0%);

					&.composite {
						background-image: linear-gradient(to right, #ccaa77 100%, #ffffff 0%);
					}
				}

				&.type2 {
					background-image: linear-gradient(to right, #dddddd 100%, #ffffff 0%);

					&.dilution75 {
						background-image: linear-gradient(to right, #dddddd 75%, #ffffff 0%);
					}

					&.dilution50 {
						background-image: linear-gradient(to right, #dddddd 50%, #ffffff 0%);
					}

					&.generic {
						background-image: linear-gradient(to right, #cccccc 100%, #ffffff 0%);

						&.dilution75 {
							background-image: linear-gradient(to right, #cccccc 75%, #ffffff 0%);
						}

						&.dilution50 {
							background-image: linear-gradient(to right, #cccccc 50%, #ffffff 0%);
						}
					}
				}

				&.type3 {
					background-image: linear-gradient(to right, #ffff33 100%, #ffffff 0%);

					&.dilution75 {
						background-image: linear-gradient(to right, #ffff33 75%, #ffffff 0%);
					}

					&.dilution50 {
						background-image: linear-gradient(to right, #ffff33 50%, #ffffff 0%);
					}

					&.generic {
						background-image: linear-gradient(to right, #cccc33 100%, #ffffff 0%);

						&.dilution75 {
							background-image: linear-gradient(to right, #cccc33 75%, #ffffff 0%);
						}

						&.dilution50 {
							background-image: linear-gradient(to right, #cccc33 50%, #ffffff 0%);
						}
					}
				}

				&.type4 {
					background-image: linear-gradient(to right, #99ff66 100%, #ffffff 0%);

					&.dilution75 {
						background-image: linear-gradient(to right, #99ff66 75%, #ffffff 0%);
					}

					&.dilution50 {
						background-image: linear-gradient(to right, #99ff66 50%, #ffffff 0%);
					}

					&.generic {
						background-image: linear-gradient(to right, #99cc66 100%, #ffffff 0%);

						&.dilution75 {
							background-image: linear-gradient(to right, #99cc66 75%, #ffffff 0%);
						}

						&.dilution50 {
							background-image: linear-gradient(to right, #99cc66 50%, #ffffff 0%);
						}
					}
				}
			}
		}

		.timer {
			display: flex;

			.startLabel, .upperLabel, .lowerLabel, .currentLabel {
				flex: 0 0 auto;
				font-size: 22px;

				.caption {
					font-size: 12px;
				}
			}

			.startLabel, .upperLabel, .lowerLabel {
				text-align: center;
			}

			.upperLabel {
				margin-left: 60px;
			}

			.currentLabel {
				text-align: left;
				height: 41px;
			}

			> .middle {
				flex: 1 0 auto;

				.progressBar {
					height: 20px;
					vertical-align: middle;

					.upperProgressBar, .lowerProgressBar {
						height: 20px;
						border-radius: 10px;
						border-width: 1px;
						border-style: solid;
						border-color: #cccccc;
						position: relative;
					}

					.lowerProgressBar {
						margin-top: -20px;
					}

					.upperProgressBar.thin, .lowerProgressBar.thin {
						height: 12px;
					}

					.lowerProgressBar.thin {
						margin-top: -12px;
					}
				}

				.times {
					display: flex;

					.middle {
						flex: 1 0 auto;

						h2 {
							margin-top: 10px;
							font-size: 20px;
							color: #387ef5;
						}
					}
				}
			}

			.timer .middle {
				padding: 0 15px 0px 15px;
			}

			.start {
				flex: 0 0 auto;
				cursor: pointer;

				&.circle {
					margin-top: 18px;
					color: #387ef5;
					border-color: #387ef5;
				}
			}

			.end {
				flex: 0 0 auto;
				cursor: pointer;

				&.circle {
					margin-top: 18px;
					color: #cc3333;
					border-color: #cc3333;
				}
			}
		}

		.lowerProgressBar.min {
			background-color: #ffcc99;
		}

		.lowerProgressBar.max {
			background-color: #ccffcc;
		}
	}
}

.weatherTypePopup, .intensityPopup {
	.item {
		background-color: #fff;
		color: #444;
		position: relative;
		z-index: 2;
		display: block;
		margin: -1px;

		.item-content {
			position: relative;
			z-index: 2;
			padding: 8px 48px 8px 8px;
			border: none;
			background-color: #fff;

			.mdi {
				position: absolute;
				top: 1px;
				right: 8px;
			}
		}
	}
}
